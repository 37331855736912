<template>
  <div>
    <Navigation />
    <!-- 个人简介 -->
    <div class="bg_box">
      <div class="noneBox"></div>
      <div class="box">
        <p class="back" @click="$router.push('/wordNav')">返回</p>
        <div class="ct_box flex">
          <div class="form_upload_box">
            <ai_title ai_title="“一言一文”AI智能简介" />
            <div class="form_ct flex">
              <p class="upLoad_text">个人姓名：</p>
              <div class="upload_box">
                <el-input v-model="form.name" placeholder="请录入个人姓名"></el-input>
              </div>
            </div>
            <ai_select selectTitle="从事行业" :select_list="selectList" @getSelectVal="get_type" />

            <div class="form_ct flex">
              <p class="upLoad_text">特长描述：</p>
              <div class="upload_box">
                <el-input v-model="form.personal" placeholder="针对此行业，请录入您自身都有哪些专业与特长"></el-input>
              </div>
            </div>
            <div class="form_ct flex">
              <p class="upLoad_text">其他描述：</p>
              <div class="upload_box">
                <el-input v-model="form.desc" placeholder="如：工作时间、工作经历、所获荣誉等"></el-input>
              </div>
            </div>
            <ai_button_file_data ref="ai_button_file_data" @file_data="file_data" />
            <ai_text_content :contentData="contentData" />
          </div>
          <wordNav />
          <aiNum title="计费规则：1000tokens = 0.9元，" text="1000tokens在700-750字左右" :visible="aiNumShow"
            @close="aiNumShow = false" @postData="postData" />
          <aiPoup :visible="aiPoupShow" @close="aiPoupShow = false" />
        </div>
      </div>
    </div>
    <footerBot />
  </div>
</template>

<script>
import wordNav from '@/components/wordNav.vue'
import ai_title from '@/components/ai_title.vue'
import ai_select from '@/components/ai_select.vue'
import ai_text_content from '@/components/ai_text_content'
import ai_button_file_data from '@/components/ai_button_file_data'
export default {
  mounted() {
    this.getUserInfo()
  },
  components: {
    wordNav,
    ai_title,
    ai_select,
    ai_text_content,
    ai_button_file_data
  },
  data() {
    return {
      form: {
        name: '',
        type: '', // 行业
        // business: '',// 擅长领域
        personal: '',// 特长描述
        desc: '',
        // keyword: '', // 关键词
      },
      selectList: [
        {
          id: '互联网/AI',
          name: '互联网/AI'
        },
        {
          id: '电子/电器/通信',
          name: '电子/电器/通信',
        },
        {
          id: '运营/客服',
          name: '运营/客服',
        },
        {
          id: '销售',
          name: '销售',
        },
        {
          id: '人力/行政/法务',
          name: '人力/行政/法务',
        },
        {
          id: '财务/审计/税务',
          name: '财务/审计/税务',
        },
        {
          id: '生产制造',
          name: '生产制造',
        },
        {
          id: '生活服务/零售',
          name: '生活服务/零售',
        },
        {
          id: '餐饮',
          name: '餐饮',
        },
        {
          id: '酒店/旅游',
          name: '酒店/旅游',
        },
        {
          id: '教育培训',
          name: '教育培训',
        },
        {
          id: '设计',
          name: '设计',
        },
        {
          id: '房地产/建筑',
          name: '房地产/建筑',
        },
        {
          id: '影视传媒',
          name: '影视传媒',
        },
        {
          id: '市场/公关/广告',
          name: '市场/公关/广告',
        },
        {
          id: '物流/仓储/司机',
          name: '物流/仓储/司机',
        },
        {
          id: '汽车',
          name: '汽车',
        },
        {
          id: '医疗器械',
          name: '医疗器械',
        },
        {
          id: '金融',
          name: '金融',
        },

      ],
      contentData: {}, //内容
      aiNumShow: false,
      aiPoupShow: false,
    }
  },
  methods: {
    get_type(val) {
      this.form.type = val
    },

    // 获取用户信息
    getUserInfo() {
      let $user_info = JSON.parse(localStorage.getItem('user_info'))
      if ($user_info) {
        this.curlGet('/api/user/info').then((res) => {
          if (res.data.code) {
            this.$user_info = res.data.data
            this.$util.setLocalStorage('user_info', res.data.data) // 写入local storage
          }
        })
      }
    },
    file_data() {
      // if (this.form.type == '') {
      //   return this.$message({
      //     message: '请输入从事行业',
      //     type: 'warning',
      //     offset: 80,
      //   });
      // }
      // if (this.form.type.business == '') {
      //   return this.$message({
      //     message: '请输入擅长领域',
      //     type: 'warning',
      //     offset: 80,
      //   });
      // }
      // if (this.form.type.personal == '') {
      //   return this.$message({
      //     message: '请输入个人荣誉',
      //     type: 'warning',
      //     offset: 80,
      //   });
      // }
      // if (this.form.type.keyWord == '') {
      //   return this.$message({
      //     message: '请输入关键词',
      //     type: 'warning',
      //     offset: 80,
      //   });
      // }
      // if (this.$user_info.ai_num < 3) {
      // } else 
      if (this.$user_info.money <= 0) {
        this.aiPoupShow = true
      } else {
        this.aiNumShow = true
        // this.postData()
      }
    },
    postData() {
      this.aiNumShow = false
      this.$refs.ai_button_file_data.loading_show()
      this.curlPost('/file/personal_profile', {
        name: this.form.name,
        type: this.form.type,
        // business: this.form.business,
        personal: this.form.personal,
        desc: this.form.desc,
        mobile: this.$user_info.mobile,
      }).then(res => {
        this.$refs.ai_button_file_data.loading_show()
        if (res.data.code) {
          this.contentData = res.data.data

          // 扣费
          this.curlPost('/api/users/money_log/spend', {
            ai_id: res.data.data.id,
            amount: res.data.data.money,
          }).then(res => {
            if (res.data.code) {
              this.getUserInfo()
            }
          })

        } else {
          this.$message.error(res.data.msg);
        }
      })
    }
  },
}
</script>

<style lang=scss scoped>
@import '@/scss/ai'
</style>